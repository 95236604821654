import React, { useState, createContext, useContext, FC, useCallback } from 'react'
import { IAlertProps, IPageContext, pageVariants } from './types'
const initialValues = {
  alertBanner: null,
  setAlertValue: () => {
    // Do nothing
  },
  bannerExcluded: false,
  setBannerExcluded: () => {
    // Do nothing
  },
  darkFontTopNav: false,
  setDarkFontTopNavValue: () => {
    // Do nothing
  },
  heroHasOverlay: true,
  setHeroHasOverlayValue: () => {
    // Do nothing
  },
  pageVariant: pageVariants.DEFAULT,
  setPageVariantValue: () => {
    // Do nothing
  },
}
const PageDataContext = createContext<IPageContext>(initialValues)
type PageDataProviderProps = {
  children: React.ReactNode
}
const PageDataProvider: FC<PageDataProviderProps> = ({ children }) => {
  const [alertBanner, setAlertBanner] = useState<IAlertProps | null>(initialValues.alertBanner)
  const [bannerExcluded, setBannerExcluded] = useState<boolean>(false)
  const [darkFontTopNav, setDarkFontTopNav] = useState<boolean>(false)
  const [heroHasOverlay, setHeroHasOverlay] = useState<boolean>(true)
  const [pageVariant, setPageVariant] = useState<pageVariants>()
  const setAlertValue = useCallback(
    (updates: IAlertProps) => {
      setAlertBanner(updates)
    },
    [alertBanner, setAlertBanner],
  )
  const setDarkFontTopNavValue = useCallback(
    (updates: boolean) => {
      setDarkFontTopNav(updates)
    },
    [darkFontTopNav, setDarkFontTopNav],
  )
  const setHeroHasOverlayValue = useCallback(
    (updates: boolean) => {
      setHeroHasOverlay(updates)
    },
    [heroHasOverlay, setHeroHasOverlay],
  )
  const setPageVariantValue = useCallback(
    (updates: pageVariants) => {
      setPageVariant(updates)
    },
    [pageVariant, setPageVariant],
  )
  return (
    <PageDataContext.Provider
      value={{
        alertBanner,
        setAlertValue,
        bannerExcluded,
        setBannerExcluded,
        darkFontTopNav,
        setDarkFontTopNavValue,
        heroHasOverlay,
        setHeroHasOverlayValue,
        pageVariant,
        setPageVariantValue,
      }}
    >
      {children}
    </PageDataContext.Provider>
  )
}
export const usePageProvider = () => {
  return useContext(PageDataContext)
}
export default PageDataProvider
