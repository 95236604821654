import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { responsiveFontSizes, ThemeProvider } from '@mui/material/styles'
import tdcTheme from '@tdcerhverv/mui-theme'
import { ThemeLayoutComponent } from './types'

const theme = responsiveFontSizes(tdcTheme)

const ThemeLayout = ({ children }: ThemeLayoutComponent) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeLayout
