import { AlertColor } from '@mui/material/Alert'
export interface IAlertProps {
  id: string
  severity: AlertColor
  body: { raw: string }
}

export enum pageVariants {
  DEFAULT = 'DEFAULT',
  SMB = 'SMB',
  ENTERPRISE = 'ENTERPRISE',
}

export interface IPageContext {
  alertBanner: IAlertProps | null
  setAlertValue: (values: IAlertProps) => void
  bannerExcluded: boolean
  setBannerExcluded: (value: boolean) => void
  darkFontTopNav: boolean
  setDarkFontTopNavValue: (values: boolean) => void
  heroHasOverlay?: boolean
  setHeroHasOverlayValue?: (values: boolean) => void
  pageVariant?: pageVariants
  setPageVariantValue: (values: pageVariants) => void
}
