// Gatsby browser
import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import ThemeLayout from './src/components/Layouts/ThemeLayout'
import PageDataProvider from './src/context/PageProvider'
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return (
    <PageDataProvider {...props}>
      <ThemeLayout>{element}</ThemeLayout>
    </PageDataProvider>
  )
}
